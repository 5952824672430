import texts from '@common/json/ecommerceReports';

export default {
  bankAccount: 'Bank Account',
  customers: 'Customers',
  dashboard: 'Dashboard',
  imageGallery: 'Images',
  gallery: 'Gallery',
  ebayMessages: 'Messages',
  emailPreferences: 'Email Preferences',
  listings: 'Listings',
  notifications: 'Notifications',
  orders: 'Orders',
  shipments: 'Shipments',
  deleteFolder: 'Delete',
  deleteFilter: 'Delete',
  inventory: 'Inventory',
  reports: 'Reports',
  settings: 'Settings',
  serviceDesk: 'Raise an issue',
  roadMap: 'Roadmap',
  accountSettings: 'Account Settings',
  profileSettings: 'Profile Settings',
  marketplaceAccounts: 'Marketplace Accounts',
  ebayPolicies: 'eBay policies',
  flatRates: 'P&P Flat rates',
  teams: 'Teams',
  users: 'Users',
  locations: 'Locations',
  blockedKeywords: 'Blocked Keywords',
  shippingMethods: 'Shipping Methods',
  productSettings: 'Inventory Attributes',
  transferBoxes: 'Transfer totes',
  jobScheduling: 'Job scheduling',
  htmlTemplates: 'HTML Templates',
  messageTemplates: 'Message Templates',
  bulkProductImport: 'Bulk Inventory Import',
  tokenRequests: 'Token requests',
  defaultValues: 'Default form values',
  repriceRules: 'Reprice rules',
  shopiagoGroup: 'World of Books Settings',
  marketplaceGroup: 'Marketplace Settings',
  listingGroup: 'Listing Settings',
  productGroup: 'Inventory Settings',
  userGuide: 'User Guide',
  ...texts,
};
