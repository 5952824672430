import {APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {enableProdMode, ErrorHandler, importProvidersFrom, Injectable, provideZoneChangeDetection} from '@angular/core';
import {MAT_AUTOCOMPLETE_DEFAULT_OPTIONS} from '@angular/material/autocomplete';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateFnsAdapter, MAT_DATE_FNS_FORMATS} from '@angular/material-date-fns-adapter';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, TitleStrategy} from '@angular/router';
import {AppComponent} from '@clock/components/app/app.component';
import {APP_ROUTES} from '@clock/components/app/app.routes';
import {AuthService} from '@clock/services/auth/auth.service';
import {CollectionsService} from '@clock/services/collections/collections.service';
import {GlobalMessageService} from '@clock/services/global-message/global-message.service';
import {HttpInterceptorService} from '@clock/services/http-interceptor/http-interceptor.service';
import {MenuRoutesService} from '@clock/services/menu-routes-service/menu-routes.service';
import {UserDataService} from '@clock/services/user-data/user-data.service';
import {CollectionsInterface} from '@common/components/collections/collections.model';
import {DATE_PIPE_CONFIG} from '@common/globals/datePipeConfig';
import {MAT_AUTOCOMPLETE_CONFIG} from '@common/globals/matAutocompleteConfig';
import {MAT_DIALOG_CONFIG} from '@common/globals/matDialogConfig';
import {MAT_FORM_FIELD_CONFIG} from '@common/globals/matFormFieldConfig';
import {MAT_RIPPLE_CONFIG} from '@common/globals/matRippleConfig';
import {MAT_SELECT_DEFAULT_CONFIG} from '@common/globals/matSelectConfig';
import {SentryErrorHandler} from '@common/globals/sentryErrorHandler';
import appVersion from '@common/json/appVersion';
import {CanDeactivateService} from '@common/services/can-deactivate/can-deactivate.service';
import {CommonService} from '@common/services/common-service/common.service';
import {ConfirmService} from '@common/services/confirm-service/confirm.service';
import {LocalStorageService} from '@common/services/local-storage/local-storage.service';
import {VersionCheckService} from '@common/services/version-check/version-check.service';
import {initSentry} from '@common/statics/initSentry';
import {GlobalMessageCommon} from '@common/ts/globalMessageCommon';
import {
  ARROW_CIRCLE_RIGHT,
  ARROW_LEFT,
  ARROW_RIGHT,
  CALENDAR,
  CHART_BAR,
  CHECK,
  CHECK_CIRCLE_SOLID,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CHEVRON_UP,
  COG,
  DOCUMENT_ADD_SOLID,
  DOTS_HORIZONTAL,
  DOTS_VERTICAL,
  DUPLICATE,
  EYE_SOLID,
  FILTER,
  HAND_SOLID,
  INFORMATION_CIRCLE,
  MENU,
  PLUS,
  PLUS_CIRCLE,
  SAVE,
  SEARCH,
  SWITCH_VERTICAL,
  THUMB_UP_SOLID,
  TRASH,
  TRUCK,
  TRUCK_SOLID,
  VIEW_BOARDS, VIEW_GRID,
  X,
  X_CIRCLE,
  X_CIRCLE_SOLID,
} from '@common/ts/heroIcons';
import {HI_ICON_SET_TOKEN} from '@common/ts/heroIconTokens';
import {PageTitleCommon} from '@common/ts/pageTitleCommon';
import {RouteCommon} from '@common/ts/routeCommon';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {enGB} from 'date-fns/locale';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';

import {ENVIRONMENT} from './environments/environment';

if (ENVIRONMENT.production) {
  enableProdMode();
}

initSentry(ENVIRONMENT.sentryDns, appVersion.collection);

@Injectable()
class TemplatePageTitleStrategy extends PageTitleCommon {

  constructor() {
    super('Collection');
  }

}

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: MAT_DATE_LOCALE, useValue: enGB},
    {provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: MAT_AUTOCOMPLETE_CONFIG},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: DATE_PIPE_CONFIG},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MAT_DIALOG_CONFIG},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: MAT_RIPPLE_CONFIG},
    {provide: MAT_SELECT_CONFIG, useValue: MAT_SELECT_DEFAULT_CONFIG},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MAT_FORM_FIELD_CONFIG},
    {provide: UserDataCommon, useExisting: UserDataService},
    {provide: GlobalMessageCommon, useExisting: GlobalMessageService, deps: [MatSnackBar]},
    {provide: CollectionsInterface, useExisting: CollectionsService},
    {provide: RouteCommon, useExisting: MenuRoutesService},
    {provide: HI_ICON_SET_TOKEN, useValue: {
      informationCircle: INFORMATION_CIRCLE, arrowLeft: ARROW_LEFT, arrowRight: ARROW_RIGHT, chevronLeft: CHEVRON_LEFT,
      dotsVertical: DOTS_VERTICAL, dotsHorizontal: DOTS_HORIZONTAL, chevronRight: CHEVRON_RIGHT, chevronDown: CHEVRON_DOWN,
      chevronUp: CHEVRON_UP, xCircleSolid: X_CIRCLE_SOLID, cog: COG, save: SAVE, filter: FILTER, switchVertical: SWITCH_VERTICAL,
      search: SEARCH, calendar: CALENDAR, chartBar: CHART_BAR, plus: PLUS, duplicate: DUPLICATE, menu: MENU, viewBoards: VIEW_BOARDS,
      truck: TRUCK, arrowCircleRight: ARROW_CIRCLE_RIGHT, x: X, xCircle: X_CIRCLE, viewGrid: VIEW_GRID, check: CHECK,
      documentAddSolid: DOCUMENT_ADD_SOLID, plusCircle: PLUS_CIRCLE, trash: TRASH, eyeSolid: EYE_SOLID, thumbUpSolid: THUMB_UP_SOLID,
      truckSolid: TRUCK_SOLID, handSolid: HAND_SOLID, checkCircleSolid: CHECK_CIRCLE_SOLID,
    }, multi: true},
    UserDataService,
    VersionCheckService,
    CommonService,
    LocalStorageService,
    provideAnimations(),
    GlobalMessageService,
    AuthService,
    ConfirmService,
    CollectionsService,
    CanDeactivateService,
    MenuRoutesService,
    {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
    provideRouter(APP_ROUTES),
    importProvidersFrom(MatDialogModule),
    importProvidersFrom(NgxPageScrollCoreModule),
  ],
}).then();
