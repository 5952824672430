import {Routes} from '@angular/router';
import {loginRedirect, notFoundRedirect} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...loginRedirect('collection'),
  {
    path: 'collection/login',
    loadChildren: () => import('../main-pages/login-check/login-check.routes'),
  },
  {
    path: 'collection/dashboard',
    loadChildren: () => import('../main-pages/dashboard/dashboard.routes'),
  },
  {
    path: 'collection/collections',
    loadChildren: () => import('../main-pages/collections/collections.routes'),
  },
  ...notFoundRedirect('collection'),
];
