import {Injectable} from '@angular/core';
import {CollectionsInterface} from '@common/components/collections/collections.model';
import {PackageTypes} from '@common/enum/packageTypes.enum';
import {GridRequest} from '@common/globals/gridRequest';
import {GridResponse} from '@common/globals/gridResponse';
import {
  BookingDayDTO, CollectionBooking, CollectionHistoryDTO,
  CollectionLineDTO,
  ExportTypes,
  OngoingCollection, ScheduledCallHistoryDTO,
} from '@common/ts/collectionInterfaces';
import {BACKEND_URL} from '@common/ts/config';
import {ItemLocation, ItemLocationOfVendorNumber, PossibleValuesDTO} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

import texts from './collections';

@Injectable()
export class CollectionsService extends CollectionsInterface {

  private serviceUrl = `${BACKEND_URL}/collection/v1`;
  private reportingUrl = `${BACKEND_URL}/reporting/v1/collection`;

  getPackageTypes(vendorNumber: string, byCollection = false): Observable<PackageTypes[]> {
    return this.fetchPackageTypes(vendorNumber, this.serviceUrl, byCollection);
  }

  override getItemLocations(): Observable<ItemLocation[]> {
    return this.http.get<ItemLocation[]>(`${this.serviceUrl}/getItemLocations`);
  }

  override getAvailableBookingDays(locationId: string): Observable<BookingDayDTO[]> {
    return this.http.get<BookingDayDTO[]>(
      `${this.serviceUrl}/availableBookingDays?itemLocationId=${locationId}`);
  }

  bookNewCollection(vendorNumber: string, collectionDate: string, pickupPackages: Record<PackageTypes, number>,
                    dropOffPackages: Record<PackageTypes, number>): Observable<boolean> {
    return this.requestNewCollection(vendorNumber, collectionDate, pickupPackages, dropOffPackages, this.serviceUrl);
  }

  getCollectionBookingGrid(request: GridRequest): Observable<GridResponse<CollectionBooking>> {
    return this.fetchCollectionBookingGridData(request, this.reportingUrl);
  }

  getOngoingCollection(): Observable<OngoingCollection> {
    return this.http.get<OngoingCollection>(`${this.serviceUrl}/getOngoingCollection`);
  }

  exportGrid(request: GridRequest, ids: string[], name: string, all: boolean, type: ExportTypes): Observable<void> {
    return this.export(request, ids, this.reportingUrl, name, all, type);
  }

  getCompanies(): Observable<PossibleValuesDTO<string>[]> {
    throw new Error(texts.notInvoked);
  }

  getItemLocationsOfCompanies(_companyIds: string[]): Observable<ItemLocation[]> {
    throw new Error(texts.notInvoked);
  }

  getItemLocationsOfVendorNumber(_vendorNumber: string): Observable<ItemLocationOfVendorNumber[]> {
    throw new Error(texts.notInvoked);
  }

  getCallHistory(_itemLocationId: string): Observable<ScheduledCallHistoryDTO[]> {
    throw new Error(texts.notInvoked);
  }

  getCollectionHistory(_itemLocationId: string): Observable<CollectionHistoryDTO[]> {
    throw new Error(texts.notInvoked);
  }

  getLineItemsGrid(request: GridRequest): Observable<GridResponse<CollectionLineDTO>> {
    return this.http.post<GridResponse<CollectionLineDTO>>(`${this.reportingUrl}/getCollectionLines`,
      this.constructCollectionsRequest(request));
  }

}
